import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Button as FlowBiteButton, Label, Radio, Select, TextInput,
} from 'flowbite-react';
import { useFieldArray, useForm } from 'react-hook-form';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { syncOfferWithLocalStorage } from '../../../Helpers';
import { DataProductDataType, useGetMyProductQuery, useUpsertDataProductMutation } from '../../../generated/gql/types';
import {
  OfferWizardLicenseAndTermsRoute, OfferWizardYourDatasetRoute,
} from '../../Routes';
import FormField from '../../library/form/FormField';
import DataProductForm from '../../../productForms';
import WizardStep from '../../library/form/WizardStep';
import Loader from '../../Loader';

function PricingPlans() {
  const params = useParams();
  const { data, loading: getLoading } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });

  const navigate = useNavigate();
  const [upsertDataProduct, { loading: upsertLoading }] = useUpsertDataProductMutation({});
  const {
    register,
    handleSubmit,
    watch,
    control,
  } = useForm<OdpsFields>();

  const { fields, remove, append } = useFieldArray({
    name: 'pricingPlans',
    control,
  });

  function updateOdpsJson(rawOdps: string | undefined, submitData: OdpsFields): string {
    const updatedFields = submitData.pricingPlans.map((field) => ({
      ...field,
      'x-wecity': {
        ...field['x-wecity'],
        maxTransactionQuantity: field.maxTransactionQuantity,
      },
    }));

    const parsedOdps = JSON.parse(rawOdps ?? '{}');
    return JSON.stringify({
      ...parsedOdps,
      product: {
        ...parsedOdps?.product ?? [],
        pricingPlans: {
          ...parsedOdps?.product?.en ?? [],
          en: updatedFields,
        },
      },
    });
  }
  useEffect(() => {
    if (data?.dataMarket?.userProduct && params.productId) {
      const odps = JSON.parse(data?.dataMarket.userProduct.openDataProductSpecification);
      const existingPricingPlans = odps?.product?.pricingPlans?.en;
      if (existingPricingPlans?.length > 0) append(existingPricingPlans);
      else {
        append({
          name: 'Bronze Plan',
          priceCurrency: 'EUR',
          price: '10',
          billingDuration: 'month',
          unit: 'one-time',
          maxTransactionQuantity: 10,
          'x-wecity': {
            tier: 'bronze',
            planId: crypto.randomUUID(),
            spikeArrestRequests: 3,
            maxTransactionQuantity: 10,
            description: 'Voorbeeld beschrijving',
          },
        });
      }
      syncOfferWithLocalStorage(params.productId, data?.dataMarket.userProduct.id);
    }
  }, [data]);

  const toggleEditTitle = (index: number, editing: boolean) => {
    const titleFieldWrapper = document.getElementById(`titlefieldwrapper-${index}`);
    const title = document.getElementById(`title-${index}`);
    if (titleFieldWrapper && title) {
      if (editing) {
        titleFieldWrapper.classList.remove('hidden');
        (titleFieldWrapper.children[0].children[0].children[0] as HTMLElement).focus(); // ugly solution to focus on input
        title.classList.add('hidden');
      } else {
        titleFieldWrapper.classList.add('hidden');
        title.classList.remove('hidden');
      }
    }
  };

  const createNewPlan = (index: number) => {
    append({
      name: 'New Plan',
      priceCurrency: 'EUR',
      price: '100',
      billingDuration: 'month',
      unit: 'recurring',
      maxTransactionQuantity: 0,
      'x-wecity': {
        tier: (['bronze', 'silver', 'gold'][index] ?? 'bornze') as 'bronze' | 'silver' | 'gold',
        planId: crypto.randomUUID(),
        spikeArrestRequests: 3,
        maxTransactionQuantity: 0,
        description: 'Voorbeeld beschrijving',
      },
    });
  };

  const deletePlan = (index: number) => {
    remove(index);
  };

  const onSubmit = handleSubmit((submittedFields) => {
    upsertDataProduct({
      variables: {
        command: {
          id: data?.dataMarket.userProduct.id ?? params.productId,
          dataType: data?.dataMarket.userProduct.dataType ?? DataProductDataType.Common,
          openDataProductSpecification: updateOdpsJson(data?.dataMarket.userProduct.openDataProductSpecification, submittedFields),
          version: 1, // TODO: Fix backend endpoint, we can only write version but not read...
        },
      },
      context: {
        headers: {
          [WeCityOrganizationIdHeader]: params.orgId,
        },
      },
    }).then((_) => {
      navigate(OfferWizardLicenseAndTermsRoute(params.orgId, params.productId));
    });
  });

  return (
    <WizardStep form={DataProductForm} currentStep={4} onSubmit={onSubmit}>
      {!getLoading
        ? (
          <>
            {
              fields?.map((field: any, index: number) => (
                <div key={field.id} className="p-2 border border-1 rounded-xl flex flex-col gap-4">
                  <div className="flex">
                    <button id={`title-${index}`} type="button" className="font-bold hover:underline text-left relative" onClick={() => toggleEditTitle(index, true)}>
                      <span className="text-2xl">{watch(`pricingPlans.${index}.name`)}</span>
                      <div className="inline ml-2 text-xs mb-auto mt-0">
                        <FontAwesomeIcon
                          icon={icon({
                            name: 'pen', family: 'classic', style: 'solid',
                          })}
                          className="mr-2"
                        />
                      </div>
                    </button>
                    {index > 0 && (
                      <button type="button" className="ml-auto" onClick={() => deletePlan(index)}>
                        <FontAwesomeIcon
                          icon={icon({
                            name: 'trash-can', family: 'classic', style: 'regular',
                          })}
                          className="text-black"
                        />
                      </button>
                    )}
                  </div>
                  <div
                    className="hidden"
                    id={`titlefieldwrapper-${index}`}
                  >
                    <TextInput
                      id={`titlefield-${index}`}
                      defaultValue={field.name}
                      {...register(`pricingPlans.${index}.name` as const, { value: fields[index]?.name })}
                      onBlur={() => toggleEditTitle(index, false)}
                    />
                  </div>
                  <FormField label="Betalingsfrequentie" fieldName="payment-frequency" appendAsterix>
                    <div className="flex">
                      <div className="flex gap-2">
                        <TextInput type="number" id="update-frequency" placeholder="bedrag" {...register(`pricingPlans.${index}.price` as const, { value: fields[index]?.price, required: true })} />

                        <div className="my-auto">euro per</div>
                        <Select id="update-frequency-unit" placeholder="week" {...register(`pricingPlans.${index}.billingDuration` as const, { value: fields[index]?.billingDuration, required: true })}>
                          <option value="week">week</option>
                          <option value="month">maand</option>
                          <option value="year">jaar</option>
                        </Select>
                      </div>
                    </div>
                  </FormField>

                  <FormField label="Type betaling" appendAsterix fieldName="paymenttype">
                    <div className="flex gap-2">
                      <Radio
                        value="one-time"
                        {...register(`pricingPlans.${index}.unit` as const, { value: field?.unit })}
                      />
                      <Label value="Eenmalig" />
                    </div>
                    <div className="flex gap-2">
                      <Radio
                        {...register(`pricingPlans.${index}.unit` as const, { value: field?.unit })}
                        value="recurring"
                      />
                      <Label htmlFor="3d" value="Periodiek" />
                    </div>
                  </FormField>
                  <FormField label="Maximum afname per termijn" appendAsterix fieldName="maximum">
                    <TextInput
                      id="max"
                      type="number"
                      placeholder="1000"
                      defaultValue={field.maxTransactionQuantity}
                      {...register(`pricingPlans.${index}.maxTransactionQuantity`, {
                        value: fields[index].maxTransactionQuantity, required: true, maxLength: 50,
                      })}
                    />
                  </FormField>
                  <FormField label="Beschrijving" appendAsterix fieldName="description" instruction="Max. 200 karakters">
                    <TextInput id="title" placeholder="Voer een korte beschrijving in van je product" required maxLength={200} defaultValue={field.description} {...register(`pricingPlans.${index}.x-wecity.description`, { value: fields[index]['x-wecity']?.description })} />
                  </FormField>
                </div>
              ))
            }

            {(fields.length - 1 < 2) && (
              <Button onClick={() => createNewPlan(fields.length - 1)} type="button" className="bg-transparent border-dashed border border-[2px] border-cyan-800 rounded-xl p-2 w-full">
                <FontAwesomeIcon
                  icon={icon({
                    name: 'plus', family: 'classic', style: 'solid',
                  })}
                  size="xl"
                  className="text-gray"
                />
              </Button>
            )}
            <div className="flex gap-x-4">
              <Link to={OfferWizardYourDatasetRoute(params.orgId, params.productId)}>
                <FlowBiteButton size="lg" color="light">
                  <div className="w-full justify-center items-center gap-2 inline-flex">
                    <div className="text-xs lg:text-sm font-medium w-full">Back</div>
                  </div>
                </FlowBiteButton>
              </Link>

              {!upsertLoading ? (
                <Button
                  onClick={onSubmit}
                  type="button"
                  className="flex items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Next</div>
                </Button>
              ) : (
                <Button
                  disabled
                  type="button"
                  className="inline-flex opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Next</div>
                </Button>
              )}
            </div>
          </>
        ) : <Loader />}
    </WizardStep>
  );
}

type PricingPlan = {
  name: string;
  priceCurrency: 'EUR';
  price: string;
  billingDuration: string,
  unit: 'one-time' | 'recurring';
  maxTransactionQuantity: number;
  'x-wecity': {
    tier: 'bronze' | 'silver' | 'gold';
    planId: string;
    spikeArrestRequests: 3;
    maxTransactionQuantity: number;
    description: string;
  }
};

type OdpsFields = {
  pricingPlans: PricingPlan[];
};

export default PricingPlans;
