import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button as FlowBiteButton, Label } from 'flowbite-react';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { syncOfferWithLocalStorage } from '../../../Helpers';
import { useGetMyProductQuery } from '../../../generated/gql/types';
import {
  OfferWizardDataHolderRoute,
  OfferWizardReviewRoute,
} from '../../Routes';
import InfoButton from '../../library/InfoButton';
import DataProductForm from '../../../productForms';
import WizardStep from '../../library/form/WizardStep';
import Loader from '../../Loader';

function Images() {
  const params = useParams();
  const { data, loading: getLoading } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });

  useEffect(() => {
    if (data?.dataMarket?.userProduct && params.productId) {
      syncOfferWithLocalStorage(params.productId, data?.dataMarket.userProduct.id);
    }
  }, [data]);

  return (

    <WizardStep form={DataProductForm} currentStep={7} onSubmit={() => { }}>
      {!getLoading
        ? (
          <>
            <div className="w-screen-md">
              <div>
                <div>
                  <Label
                    htmlFor="image"
                    value="Upload een afbeelding die past bij je dataset of applicatie"
                    className="font-bold text-gray-300"
                  />
                  <span className="text-sm text-gray-200 mt-1 block">We raden een plaatje aan zoals een grafiek of kaart, met geen of zo weinig mogelijk tekst</span>
                </div>
                <div className="w-full h-[240px] bg-gray-50 rounded-md border-dashed border-2" />
              </div>

              <div>
                <div>
                  <Label
                    htmlFor="image"
                    value="Upload een afbeelding om je product te promoten."
                    className="font-bold text-gray-300"
                  />
                  <span className="text-sm text-gray-200 mt-1 block">We raden een krachtig en simpele afbeelding die de potentie van je product benadrukt. Een resolutie van 1280 x 720 pixels is optimaal.</span>
                </div>
                <div className="w-full h-[240px] bg-gray-50 rounded-md border-dashed border-2" />
              </div>
            </div>
            <div className="flex gap-x-4">
              <Link to={OfferWizardDataHolderRoute(params.orgId, params.productId)}>
                <FlowBiteButton size="lg" color="light">
                  <div className="w-full justify-center items-center gap-2 inline-flex">
                    <div className="text-xs lg:text-sm font-medium w-full">Back</div>
                  </div>
                </FlowBiteButton>
              </Link>

              <Link to={OfferWizardReviewRoute(params.orgId, params.productId)}>
                <InfoButton>
                  <div className="text-xs lg:text-sm font-medium w-full">Next</div>
                  <FontAwesomeIcon
                    icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                    size="lg"
                    className="text-white"
                  />
                </InfoButton>
              </Link>
            </div>
          </>
        ) : <Loader />}
    </WizardStep>
  );
}

export default Images;
