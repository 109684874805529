import React, { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ProductCard from '../library/ProductCard';
import UseCaseCard from '../library/UseCaseCard';
import Hero from './Hero';
import {
  DataProduct,
  useGetDataProductsQuery, UserSearchQuery, useSearchDataProductsLazyQuery,
} from '../../generated/gql/types';
import { CatalogProductRoute } from '../Routes';
import validateOdps from '../../Helpers';

function CatalogProductsOverview() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, loading } = useGetDataProductsQuery({
    fetchPolicy: 'network-only',
  });
  const { register, setValue } = useForm<UserSearchQuery>();
  const [searchDataProducts, {
    data: searchData, loading: searchLoading,
  }] = useSearchDataProductsLazyQuery({
    fetchPolicy: 'network-only',
  });

  let timer: NodeJS.Timeout | null = null;

  const submitSearch = (searchString: string) => {
    if (searchString.length < 3) {
      return;
    }

    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSearchParams({ searchString });
    }, 500);
  };

  useEffect(() => {
    const query = searchParams.get('searchString');
    if (query) {
      searchDataProducts({
        fetchPolicy: 'network-only',
        variables: {
          query: {
            searchString: query,
          },
        },
      });
    }
  }, [searchParams]);

  const clearQuery = () => {
    setSearchParams(undefined);
    setValue('searchString', '');
  };

  function renderProductWithUseCases(dp: Partial<DataProduct>) {
    const odps = validateOdps(dp.openDataProductSpecification);

    if (odps) {
      if (odps.product.en.useCases && odps.product.en.useCases.length > 0 && dp.id) {
        return (
          <Fragment key={`catalogproduct-${dp.id}`}>
            <ProductCard odps={odps} productRoute={CatalogProductRoute(dp.id)} />
            <UseCaseCard productId={dp.id} useCase={odps.product.en.useCases[0].useCase} />
          </Fragment>
        );
      }
      return (
        <ProductCard key={`catalogproduct-${dp.id}`} odps={odps} productRoute={CatalogProductRoute(dp.id)} />
      );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment key={`catalogproduct-${dp.id}`} />;
  }

  return (
    <>
      <Hero />
      <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100">
        <form
          className="flex flex-col py-4"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="left-0 flex items-center max-w-md">
            <input
              type="text"
              className="left-0 flex-grow bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder="Zoek producten"
              {...register('searchString', {
                required: true,
                onChange: (e) => {
                  submitSearch(e.target.value);
                },
                value: searchParams.get('searchString') ?? '',
              })}
            />
            <button
              type="button"
              onClick={clearQuery}
              className="-left-6 relative rounded-l-md text-sm font-medium text-gray-500"
            >
              <FontAwesomeIcon icon={icon({ name: 'xmark', family: 'classic', style: 'regular' })} color="gray" />
            </button>
          </div>
        </form>
      </div>
      <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100">

        {(!loading && !searchLoading) ? (
          <div
            className="columns-1 md:columns-2 lg:columns-3 gap-12 space-y-12 pb-12 lg:w-full break-inside-avoid-column"
          >
            {searchParams.get('searchString') && searchData?.dataProductSearch ? (
              <>
                {(searchData.dataProductSearch ?? []).map(
                  (dataProduct: Partial<DataProduct>) => renderProductWithUseCases(dataProduct),
                )}
              </>
            ) : (
              <>
                {(data?.dataMarket.products ?? []).map(
                  (dataProduct: Partial<DataProduct>) => renderProductWithUseCases(dataProduct),
                )}
              </>
            )}
          </div>
        ) : (
          <div className="columns-3 md:columns-2 lg:columns-3 gap-12 space-y-12 py-12 w-full">
            <div
              key="loading-1"
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              key="loading-2"
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              key="loading-3"
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[505px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default CatalogProductsOverview;
