import React from 'react';
import {
  DeepPartial, FlowbiteTableTheme, FlowbiteTabTheme, Table, Tabs,
} from 'flowbite-react';
import { Link } from 'react-router-dom';
import {
  FaAlignLeft, FaCode, FaListUl, FaRegCheckCircle,
} from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ODPS } from '../../Helpers';

function MetaDataRow({ label, value }: MetaDataProps) {
  if (value === undefined) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (<></>);
  }

  return (
    <Table.Row className="bg-gray-50">
      <Table.Cell className="whitespace-nowrap font-semibold text-gray-900">
        {label}
      </Table.Cell>
      <Table.Cell><p className="line-clamp-1">{value}</p></Table.Cell>
    </Table.Row>
  );
}

type MetaDataProps = {
  label: string,
  value: string | undefined
};

function ProductTabGroup({ odps, planId }: ProductTabGroupProps) {
  const customTabItem: DeepPartial<FlowbiteTabTheme> = {
    tabpanel: 'py-0',
    tablist: {
      styles: {
        fullWidth: 'divide-x divide-gray-400 shadow-none',
      },
      tabitem: {
        icon: 'mr-2 h-3 w-3',
        base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:ring-0',
        styles: {
          fullWidth: {
            active: {
              on: 'bg-white text-gray-900 text-sm',
              off: 'bg-gray-300 text-gray-600 text-sm',
            },
          },
        },
      },
    },
  };

  const customTableTheme: DeepPartial<FlowbiteTableTheme> = {
    root: {
      base: 'w-full text-left text-sm text-gray-500 rounded-none bg-gray-50',
      shadow: 'bg-gray-50',
    },
    body: {
      base: 'bg-gray-50',
      cell: {
        base: 'px-6 py-4 bg-gray-50 rounded-none',
      },
    },
    head: {
      cell: {
        base: 'px-6 py-4 rounded-none',
      },
    },
  };

  return (
    <div className="overflow-x-auto flex w-full bg-white rounded-lg">
      <Tabs.Group style="fullWidth" theme={customTabItem} className="w-full gap-0">
        <Tabs.Item
          title="Beschrijving"
          icon={FaAlignLeft}
        >
          <div className="px-6">
            <div className="text-2xl font-bold py-4">{odps?.product?.en?.name}</div>
            <div className="text-gray-500 text-base">{odps?.product?.en?.description}</div>
          </div>
        </Tabs.Item>
        <Tabs.Item title="Metadata" className="bg-gray-50" icon={FaListUl}>
          <div className="flex-col p-0 bg-gray-50 h-full">
            <div
              className="pl-6 pt-4 pb-4 w-full bg-white text-2xl font-bold"
            >
              {odps?.product?.en?.name}
            </div>
            <div className="overflow-x-auto">
              <Table theme={customTableTheme}>
                <Table.Body className="divide-y border-gray-200 border-b border-t">
                  <MetaDataRow label="Product ID" value={odps?.product?.en?.productID} />
                  <MetaDataRow label="Versie" value={odps?.product?.en?.version} />
                  <MetaDataRow
                    label="Bestandsformaten"
                    value={(odps?.product?.en?.outputFileFormats ?? []).length > 0 ? odps?.product?.en?.outputFileFormats.join(', ') : undefined}
                  />
                  <MetaDataRow
                    label="Infrastructuur scripting"
                    value={odps?.product?.dataOps?.infrastructure?.format}
                  />
                  <MetaDataRow
                    label="Infrastructuur regio"
                    value={odps?.product?.dataOps?.infrastructure?.region}
                  />
                  <MetaDataRow
                    label="Update frequentie"
                    value={`${odps?.product?.SLA?.updateFrequency?.value} ${odps?.product?.SLA?.updateFrequency?.unit}`}
                  />
                  <MetaDataRow label="Licentie" value={odps?.product?.license?.scope?.definition} />
                  <MetaDataRow
                    label="Categorieën"
                    value={(odps?.product?.en?.categories ?? []).length > 0 ? odps?.product?.en?.categories.join(', ') : undefined}
                  />
                  <MetaDataRow label="Aanbieder" value={odps?.product?.dataHolder?.legalName} />
                  <MetaDataRow label="Aanbieder ID" value={odps?.product?.dataHolder?.businessId} />
                </Table.Body>
              </Table>
            </div>
          </div>
        </Tabs.Item>
        <Tabs.Item title="Documentatie" icon={FaCode}>
          <div className="px-6">
            <div className="text-2xl font-bold py-4 flex items-center gap-x-2">
              Documentatie
              {odps?.product?.dataOps?.infrastructure?.schemaLocationURL && (
                <div className="text-sm">
                  <Link target="_blank" to={odps?.product?.dataOps?.infrastructure?.schemaLocationURL}>
                    Open schema documentatie in nieuwe tab
                    {' '}
                    <FontAwesomeIcon
                      icon={icon({ name: 'external-link', family: 'classic', style: 'solid' })}
                    />
                  </Link>
                </div>
              )}
            </div>
            {planId && (
              <div className="text-gray-500 text-base">
                Data endpoint voor jouw abonnement:
                {process.env.REACT_APP_ENVIRONMENT === 'production' ? (
                  <div className="border border-gray-400 bg-gray-200 rounded-lg p-1">
                    https://services.platform.wecity.nl/data-product-plan/
                    {planId}
                    ?wecity-api-key=mijn-api-key
                  </div>
                ) : (
                  <div className="border border-gray-400 bg-gray-200 rounded-lg p-1">
                    https://services.acc-platform.wecity.nl/data-product-plan/
                    {planId}
                    ?wecity-api-key=mijn-api-key
                  </div>
                )}
              </div>
            )}

          </div>
        </Tabs.Item>
        <Tabs.Item title="Gebruiksvoorwaarden" icon={FaRegCheckCircle}>
          <div className="px-6">
            <div className="text-2xl font-bold py-4 flex items-center gap-x-2">
              Gebruiksvoorwaarden
              {odps?.product?.license?.privacy?.dpaURL && (
                <div className="text-sm">
                  <Link target="_blank" to={odps?.product?.license?.privacy?.dpaURL}>
                    Open in nieuwe tab
                    {' '}
                    <FontAwesomeIcon
                      icon={icon({ name: 'external-link', family: 'classic', style: 'solid' })}
                    />
                  </Link>
                </div>
              )}
            </div>
            <div className="divide-x flex gap-y-4 pb-4">
              {(odps?.product?.license?.scope?.rights ?? []).map((right: any) => (
                <div
                  key={`right-${right}`}
                  className="first:pl-0 text-primary-500 px-4 text-sm font-semibold"
                >
                  {right}
                </div>
              ))}
            </div>
            <div className="text-gray-500 text-base">{odps?.product?.license?.scope?.definition}</div>
            {(odps?.product?.license?.privacy?.applicaplePrivacyLaws ?? []).length > 0 && (
              <>
                <div className="font-semibold pt-2">Privacy policies</div>
                <ul className="py-2 list-disc list-inside">
                  {(odps?.product?.license?.privacy?.applicaplePrivacyLaws ?? []).map((law: any) => (
                    <li
                      key={`law-${law}`}
                      className="text-base text-gray-500"
                    >
                      {law}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </Tabs.Item>
      </Tabs.Group>
    </div>
  );
}

type ProductTabGroupProps = {
  odps: ODPS
  planId?: string
};

export default ProductTabGroup;
