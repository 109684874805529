import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { useGetMyProductQuery } from '../../../generated/gql/types';
import {
  MyOfferingsOverviewRoute, OfferWizardAutoFillOdpsRoute,
} from '../../Routes';
import InfoButton from '../../library/InfoButton';
import { syncOfferWithLocalStorage } from '../../../Helpers';

function Language() {
  const params = useParams();
  const { data } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });

  useEffect(() => {
    if (data?.dataMarket?.userProduct && params.productId) {
      syncOfferWithLocalStorage(params.productId, data?.dataMarket.userProduct.id);
    }
  }, [data]);

  return (
    <div
      className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full flex-col p-10 bg-white space-y-2 my-5"
    >
      <div className="self-start">
        <Link to={MyOfferingsOverviewRoute(params.orgId)}>
          <div className="flex gap-x-2 items-center">
            <FontAwesomeIcon
              icon={icon({ name: 'angle-left', family: 'classic', style: 'solid' })}
              size="lg"
              className="text-primary-500"
            />
            <div className="text-primary-500">Back to my offerings</div>
          </div>
        </Link>
      </div>
      <div className="text-gray-900 text-3xl font-bold">Begin met het aanbieden van een product</div>

      <div className="text-gray-900 text-base font-bold self-start">
        In welke taal voert wil je je product invoeren?
      </div>
      <select
        defaultValue="english"
        className="self-start p-2 mb-4 text-sm text-gray-900 border rounded-lg bg-gray-50 max-w-sm w-full"
      >
        <option value="english">
          Nederlands
        </option>
        <option value="english">
          English
        </option>
      </select>

      <Link to={OfferWizardAutoFillOdpsRoute(params.orgId, params.productId)}>
        <InfoButton>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <div className="text-xs lg:text-sm font-medium w-full">Let's start</div>
          <FontAwesomeIcon
            icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
            size="lg"
            className="text-white"
          />
        </InfoButton>
      </Link>
    </div>
  );
}

export default Language;
