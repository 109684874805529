import React from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { Progress } from 'flowbite-react';
import { IWizardForm } from '../../../productForms';
import { MyOfferingsOverviewRoute } from '../../Routes';

export interface IWizardStepProps {
  children: React.ReactNode;
  form: IWizardForm;
  currentStep: number;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
}

function WizardStep({
  children, form, currentStep, onSubmit,
}: IWizardStepProps) {
  const params = useParams();

  const determineClassName = (index: number) => {
    if (currentStep < index) {
      // step has not been reached yet
      return 'text-gray-500 text-sm';
    }
    if (currentStep === index) {
      // step is current step
      return 'bg-gray-100';
    }
    // step has been completed
    return 'text-primary-500 font-bold';
  };
  const stepIcon = (index: number) => {
    if (currentStep <= index) {
      return <FontAwesomeIcon className="px-1 mr-2" icon={icon({ name: 'circle-check', style: 'regular' })} />;
    }
    return <FontAwesomeIcon className="px-1 mr-2 text-primary" icon={icon({ name: 'badge-check' })} />;
  };
  const completionPercentage = (currentStep / form.steps.length) * 100;

  return (
    <div
      className="mx-auto relative max-w-screen-xl flex flex-col gap-5 justify-center w-full my-5"
    >
      <div className="flex gap-5">
        <div className="w-[300px] ">
          <div className="border border-1 flex flex-col gap-5 bg-white rounded-xl">
            <div className=" p-3">
              <Link className="flex gap-x-2 items-center text-sm" to={MyOfferingsOverviewRoute(params.orgId)}>
                <FontAwesomeIcon
                  icon={icon({ name: 'angle-left', family: 'classic', style: 'solid' })}
                  size="lg"
                  className="text-primary-500"
                />
                <div className="text-primary-500 text-sm">Back to my offerings</div>
              </Link>
              <ul className="flex flex-col gap-2 mt-5">
                {
                  form.steps.map((step, index) => (
                    <li key={step.name} className={`p-2 rounded-xl ${determineClassName(index)}`}>
                      {stepIcon(index)}
                      {step.name}
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className="border-t-[1px] flex flex-col py-5">
              <div className="px-3 my-3 text-sm text-gray">
                {completionPercentage.toString().split('.')[0]}
                % Voltooid
                <Progress progress={completionPercentage} />
              </div>
              <button className="hover:bg-gray-100 p-3 text-left disabled:text-gray-300  disabled:hover:bg-transparent" type="button" disabled>
                <FontAwesomeIcon
                  icon={icon({ name: 'down-to-bracket', family: 'classic', style: 'solid' })}
                  className="mr-4"
                />
                Opslaan en sluiten
              </button>
              <button className="hover:bg-gray-100 p-3 text-left disabled:text-gray-300  disabled:hover:bg-transparent" type="button" disabled>
                <FontAwesomeIcon
                  icon={icon({ name: 'circle-info', family: 'classic', style: 'regular' })}
                  className="mr-4"
                />
                Help
              </button>
              <button className="hover:bg-gray-100 p-3 text-left text-red disabled:text-gray-300 disabled:hover:bg-transparent" type="button" disabled>
                <FontAwesomeIcon
                  icon={icon({ name: 'trash-can', family: 'classic', style: 'regular' })}
                  className="mr-4"
                />
                Verwijder product
              </button>
            </div>
          </div>
        </div>

        <div
          className="elative max-w-screen-xl flex w-full flex-col px-5 py-10 bg-white rounded-xl gap-10"
        >
          <div>
            <div className="flex w-full gap-3 items-center">
              <div className="font-bold text-2xl">{form.title}</div>
              <div className="bg-gray w-[1px] h-8" />
              <div className="text-gray text-sm">{form.steps[currentStep].name}</div>
            </div>
            <div>{form.steps[currentStep].description}</div>
          </div>
          <form onSubmit={onSubmit} className="flex-col space-y-6 min-h-full w-full">
            {children}
          </form>
        </div>
      </div>
    </div>
  );
}

export default WizardStep;
