import { Outlet } from 'react-router-dom';
import React from 'react';
import {
  type DeepPartial, Navbar,
} from 'flowbite-react';
import { FlowbiteNavbarLinkTheme } from 'flowbite-react/lib/esm/components/Navbar/NavbarLink';
import { FlowbiteNavbarCollapseTheme } from 'flowbite-react/lib/esm/components/Navbar/NavbarCollapse';
import { FlowbiteNavbarRootTheme } from 'flowbite-react/lib/esm/components/Navbar/Navbar';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import Footer from './Footer';
import wecity_logo_main from '../images/wecity_logo.svg';
import UserProfile from './library/UserProfile';
import Registration from './library/Registration';
import { CatalogRoute, LandingRoute } from './Routes';
import MySubscriptionsNavBarLink from './data-market/MySubscriptionsNavBarLink';
import MyOfferingsNavBarLink from './data-market/MyOfferingsNavBarLink';

function Layout() {
  const { idTokenPayload } = useOidcIdToken();

  const customLinkTheme: DeepPartial<FlowbiteNavbarLinkTheme> = {
    base: 'pl-0 text-base h-[64px] items-center',
    active: {
      on: 'text-white border-primary-600 border-b-2 box-sizing bg-gray-50',
      off: 'border-b-2 border-white',
    },
  };

  const customCollapseTheme: DeepPartial<FlowbiteNavbarCollapseTheme> = {
    list: 'mt-4 space-x-3 flex flex-col md:mt-0 md:flex-row md:text-sm md:font-medium items-center h-full',
  };

  const navbarTheme: DeepPartial<FlowbiteNavbarRootTheme> = {
    base: 'bg-white px-2 dark:border-gray-700 dark:bg-gray-800 sm:px-4 items-center',
    inner: {
      base: 'max-w-screen-xl mx-auto flex flex-wrap items-center justify-between h-full',
    },
  };

  return (
    <>
      <Navbar theme={navbarTheme} fluid rounded className="bg-white items-center h-16">
        <Navbar.Brand href={LandingRoute()}>
          <img src={wecity_logo_main} className="h-8" alt="WeCity Logo" />
        </Navbar.Brand>
        <div className="flex items-center md:order-1 gap-3 h-full">
          <Navbar.Collapse className="items-center justify-self-start pr-0 lg:pr-8" theme={customCollapseTheme}>
            <Navbar.Link
              href={CatalogRoute()}
              theme={customLinkTheme}
              className="px-4 text-base flex text-primary-700 font-bold"
            >
              Catalogus
            </Navbar.Link>

            {idTokenPayload && (
              <>
                <MySubscriptionsNavBarLink />
                <MyOfferingsNavBarLink />
              </>
            )}
          </Navbar.Collapse>
          {!idTokenPayload && <Registration />}
          <UserProfile />
          <Navbar.Toggle />
        </div>
      </Navbar>
      <Outlet />
      <Footer />
    </>
  );
}

export default Layout;
