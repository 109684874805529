import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DataProduct,
  useGetDataProductsQuery,
} from '../../generated/gql/types';
import PricingPlanCard from '../library/PricingPlanCard';
import GenericSupportCard from '../library/GenericSupportCard';
import validateOdps, { ODPS } from '../../Helpers';
import ProductCard from '../library/ProductCard';
import ProductTabGroup from '../library/ProductTabGroup';

function CatalogProduct() {
  const params = useParams();
  const [product, setProduct] = useState<CatalogProductModel | undefined>(undefined);
  const { data, loading } = useGetDataProductsQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading && data?.dataMarket.products) {
      const entity = data?.dataMarket.products?.find((p) => p.id === params.productId);

      if (entity !== undefined) {
        setProduct({
          dataProductEntity: entity,
          odps: validateOdps(entity?.openDataProductSpecification),
        });
      }
    }
  }, [loading, data]);

  return (
    <div className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full bg-gray-100 py-6">

      {!loading && product && product.odps !== undefined ? (
        <div className="flex-col w-full justify-center">
          <div className="flex-col space-y-6 lg:flex lg:flex-row lg:space-x-6 lg:space-y-0">
            <ProductCard isMini odps={product.odps} />
            <ProductTabGroup odps={product.odps} />
          </div>
          <div className="relative flex py-5 items-center">
            <div className="flex-grow border-t border-gray-300" />
            <span className="flex-shrink mx-4 text-gray-600 text-lg items-center font-semibold">Abonnementsvormen</span>
            <div className="flex-grow border-t border-gray-300" />
          </div>

          <div
            className="columns-1 md:columns-2 lg:columns-3 gap-12 space-y-6 lg:w-full break-inside-avoid-column"
          >
            {product.odps?.product?.pricingPlans?.en.map((pricingPlan: any) => (
              <div key={`pricplan-${pricingPlan.name}`} className="flex lg:block justify-center">
                <PricingPlanCard pricingPlan={pricingPlan} productName={product?.odps?.product?.en?.name ?? 'Plan'} productId={product.dataProductEntity.id} />
              </div>
            ))}
            <GenericSupportCard />
          </div>
        </div>
      ) : (
        <div className="flex-col w-full justify-center">
          <div className="flex-col space-y-6 lg:flex lg:flex-row lg:space-x-6 lg:space-y-0">
            <div className="w-[350px] relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[300px] rounded-lg" />
            <div
              className="flex grow rounded-lg w-full justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse"
            />
          </div>
          <div className="columns-3 md:columns-2 lg:columns-3 gap-12 space-y-12 py-6 w-full">
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[384px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[384px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
            <div
              className="justify-start relative bg-gray-300 dark:bg-gray-700 animate-pulse h-[384px] w-full rounded-lg break-inside-avoid max-w-sm"
            />
          </div>
        </div>
      )}
    </div>
  );
}

type CatalogProductModel = {
  dataProductEntity: DataProduct
  odps: ODPS | undefined
};

export default CatalogProduct;
