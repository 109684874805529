import React, { Fragment } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CategoriesToIcons({ categories }: CategoriesToIconsProps) {
  return (
    <>
      {categories.slice(0, 5).map((category) => (
        <Fragment key={`odsicon-${category}`}>
          {category === 'construction' && (
            <FontAwesomeIcon
              icon={icon({ name: 'construction', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {category === 'tree' && (
            <FontAwesomeIcon
              icon={icon({ name: 'tree', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {category === 'water' && (
            <FontAwesomeIcon
              icon={icon({ name: 'droplet', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {category === 'car' && (
            <FontAwesomeIcon
              icon={icon({ name: 'car', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {category === 'truck' && (
            <FontAwesomeIcon
              icon={icon({ name: 'truck', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'bicycle' || category === 'bike') && (
            <FontAwesomeIcon
              icon={icon({ name: 'bicycle', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'weather' || category === 'climate') && (
            <FontAwesomeIcon
              icon={icon({ name: 'sun', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'map') && (
            <FontAwesomeIcon
              icon={icon({ name: 'map', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'gps') && (
            <FontAwesomeIcon
              icon={icon({ name: 'location-dot', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'energy') && (
            <FontAwesomeIcon
              icon={icon({ name: 'bolt', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'road') && (
            <FontAwesomeIcon
              icon={icon({ name: 'road', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
          {(category === 'border') && (
            <FontAwesomeIcon
              icon={icon({ name: 'map-signs', family: 'classic', style: 'solid' })}
              size="2x"
              className="text-primary-800"
            />
          )}
        </Fragment>
      ))}
    </>
  );
}

type CategoriesToIconsProps = {
  categories: string[]
};

export default CategoriesToIcons;
