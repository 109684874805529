import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Table } from 'flowbite-react';
import { Link, useParams } from 'react-router-dom';
import Badge from './Badge';
import Card from './Card';
import CategoriesToIcons from './CategoriesToIcons';
import InfoButton from './InfoButton';
import TagList from './TagList';
import { ODPS, randomColorWithSeed } from '../../Helpers';
import { DataProduct } from '../../generated/gql/types';
import { OfferWizardAutoFillOdpsRoute } from '../Routes';

function ProductOfferCard({
  odps, productRoute, product, isMini,
}: ProductProps) {
  const params = useParams();
  function renderHeader() {
    return (
      <div
        style={{ backgroundColor: randomColorWithSeed(odps.product.en.name.length) }}
        className="hidden pt-0 lg:inline-flex w-full h-36 justify-center items-center gap-6 rounded-t-lg lg:text-2xl"
      >
        <CategoriesToIcons categories={odps?.product?.en?.categories ?? []} />
      </div>
    );
  }

  function renderDetailsButton() {
    if (!productRoute) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    return (
      <div className="flex pt-2 justify-center lg:justify-end w-full">
        <Link to={productRoute}>
          <InfoButton>
            <div className="text-xs lg:text-sm font-medium">Meer informatie</div>
            <FontAwesomeIcon
              icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
              size="lg"
              className="text-white"
            />
          </InfoButton>
        </Link>
      </div>
    );
  }

  function addStateBadge(): React.JSX.Element | string {
    if (product?.state === 'DRAFT') {
      return (<Badge key="draft" color="green">Draft</Badge>);
    } if (product?.state === 'PENDING') {
      return (<Badge key="draft" color="warning">Pending</Badge>);
    } if (product?.state === 'AMEND') {
      return (<Badge key="draft" color="failure">Amend</Badge>);
    }

    return (<Badge key="live" color="info">Live</Badge>);
  }

  return (
    <Card
      header={renderHeader()}
      backgroundColor="white"
      isMini={isMini}
    >
      <div className="flex">

        <div
          style={{ backgroundColor: randomColorWithSeed(odps.product.en.name.length) }}
          className="flex-col p-5 rounded-tl-lg rounded-bl-lg lg:hidden"
        >
          <div className="flex h-full items-center">
            {(odps?.product?.en?.categories ?? []).length > 0 && (
              <div className="flex-col space-y-4 text-xs lg:text-base">
                <CategoriesToIcons categories={odps?.product?.en?.categories ?? []} />
              </div>
            )}
          </div>
        </div>

        <div className="flex-col grow p-3 lg:p-5 justify-between items-center inline-flex">
          {(odps?.product?.en?.categories ?? []).length > 0 && (
            <div className="w-full h-5 justify-start items-start gap-2 inline-flex mb-2">
              {addStateBadge()}
              {(odps?.product?.en?.categories ?? []).map((category: string) => (
                <Badge key={category}>{category}</Badge>
              ))}
            </div>
          )}

          <div className="flex-col justify-start items-start inline-flex w-full">
            <div className="text-left w-full text-gray-900 text-base lg:text-2xl font-bold">
              {odps?.product?.en?.name}
            </div>
            {!isMini && (
              <div
                className="basis-0 text-gray-500 text-base font-normal leading-normal"
              >
                <p className="line-clamp-3 mb-2 text-sm lg:text-base">{odps?.product?.en?.description}</p>
              </div>
            )}
          </div>
          <div className="pb-1">
            <TagList tags={odps?.product?.en?.tags} limit={5} />
          </div>
          <div className="w-full">
            <Table className="w-full">
              <Table.Body className="overflow-x-hidden text-left w-full items-start">
                <Table.Row className="border-t border-solid border-gray-200 ">
                  <Table.Cell className="text-gray-400 p-0 pr-1 py-1 inline-flex space-x-2">
                    <div className="text-gray-400">
                      Aanbieder
                    </div>
                    <div className="text-gray-800 font-medium whitespace-nowrap">
                      {odps?.product?.dataHolder?.legalName ?? 'unknown'}
                    </div>
                  </Table.Cell>
                  <Table.Cell className="p-1 px-2 inline-flex  space-x-2">
                    <div className="text-gray-400">
                      Formaat
                    </div>
                    <div className="text-gray-800 font-medium whitespace-nowrap">
                      {odps?.product?.dataAccess?.format ?? 'unknown'}
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          {((!isMini || productRoute) && product?.state === 'ACCEPTED') ? (
            renderDetailsButton()
          ) : (
            <div className="flex pt-2 justify-center lg:justify-end w-full">
              <Link to={OfferWizardAutoFillOdpsRoute(params.orgId, product?.id)}>
                <InfoButton>
                  <div className="text-xs lg:text-sm font-medium">Back to setup</div>
                  <FontAwesomeIcon
                    icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                    size="lg"
                    className="text-white"
                  />
                </InfoButton>
              </Link>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

export default ProductOfferCard;

type ProductProps = {
  odps: ODPS
  productRoute?: string
  product?: Partial<DataProduct>
  isMini?: boolean
};
