import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button as FlowBiteButton } from 'flowbite-react/lib/esm/components/Button/Button';
import { Button, Textarea } from 'flowbite-react';
import { WeCityOrganizationIdHeader } from '../../../Global';
import {
  DataProductDataType,
  UpsertUserProductCommand,
  useGetMyProductQuery,
  useUpsertDataProductMutation,
} from '../../../generated/gql/types';
import {
  OfferWizardGetStartedRoute, OfferWizardReviewRoute,
  OfferWizardYourProductRoute,
} from '../../Routes';
import { OdpsValidationResult, syncOfferWithLocalStorage, validateOdpsWithExceptions } from '../../../Helpers';
import WizardStep from '../../library/form/WizardStep';
import DataProductForm from '../../../productForms';
import Loader from '../../Loader';

function AutofillOdps() {
  const navigate = useNavigate();
  const params = useParams();
  const { data, loading } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });

  const {
    register,
    unregister,
    watch,
    handleSubmit,
  } = useForm<UpsertUserProductCommand>({
    defaultValues: {
      id: params.productId,
      dataType: DataProductDataType.Common,
      version: 1,
    },
  });

  const [upsertDataProduct] = useUpsertDataProductMutation({});
  const odpsValueWatch = watch('openDataProductSpecification');

  useEffect(() => {
    if (data?.dataMarket?.userProduct && params.productId) {
      syncOfferWithLocalStorage(params.productId, data?.dataMarket.userProduct.id);
    }
  }, [data]);

  const [odpsValidationResult, setOdpsValidationResult] = useState<OdpsValidationResult | undefined>(undefined);
  const onSubmit = handleSubmit((fields) => {
    upsertDataProduct({
      variables: {
        command: {
          id: data?.dataMarket.userProduct.id ?? params.productId,
          dataType: fields.dataType,
          openDataProductSpecification: fields.openDataProductSpecification,
          version: fields.version,
        },
      },
      context: {
        headers: {
          [WeCityOrganizationIdHeader]: params.orgId,
        },
      },
    }).then((_) => {
      navigate(OfferWizardReviewRoute(params.orgId, params.productId));
    });
  });

  useEffect(() => {
    setOdpsValidationResult(undefined);
    if (odpsValueWatch) {
      try {
        const odps = validateOdpsWithExceptions(odpsValueWatch);
        setOdpsValidationResult({ errorMessage: undefined, success: true, validOdps: odps });
      } catch (exception: any) {
        setOdpsValidationResult({ errorMessage: exception?.toString(), success: false, validOdps: undefined });
      }
    }
  }, [register, unregister, odpsValueWatch]);

  return (
    <WizardStep form={DataProductForm} currentStep={0} onSubmit={onSubmit}>
      {!loading
        ? (
          <>
            <Textarea
              rows={10}
              className="w-full"
              {...register('openDataProductSpecification', { value: data?.dataMarket?.userProduct?.openDataProductSpecification ?? '' })}
            />
            <div>
              {odpsValidationResult ? (
                <div>
                  Odps Validation:
                  {odpsValidationResult.success ? 'Success' : odpsValidationResult.errorMessage}
                </div>
              ) : ('')}
            </div>

            <div className="flex gap-x-4">
              <Link to={OfferWizardGetStartedRoute(params.orgId, params.productId)}>
                <FlowBiteButton size="lg" color="light">
                  <div className="w-full justify-center items-center gap-2 inline-flex">
                    <div className="text-xs lg:text-sm font-medium w-full">Back</div>
                  </div>
                </FlowBiteButton>
              </Link>
              <Link to={OfferWizardYourProductRoute(params.orgId, params.productId)}>
                <Button
                  disabled={!odpsValidationResult?.success}
                  type="submit"
                  className="flex items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Next</div>
                </Button>
              </Link>
              <Link to={OfferWizardYourProductRoute(params.orgId, params.productId)}>
                <Button
                  type="button"
                  className="inline-flex opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">
                    Skip
                  </div>
                </Button>

              </Link>
            </div>
          </>
        ) : <Loader />}
    </WizardStep>
  );
}

export default AutofillOdps;
