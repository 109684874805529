import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Button as FlowBiteButton, Select, TextInput, Textarea,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { syncOfferWithLocalStorage } from '../../../Helpers';
import { DataProductDataType, useGetMyProductQuery, useUpsertDataProductMutation } from '../../../generated/gql/types';
import {
  OfferWizardDataHolderRoute, OfferWizardPricingPlansRoute,
} from '../../Routes';
import FormField from '../../library/form/FormField';
import DataProductForm from '../../../productForms';
import WizardStep from '../../library/form/WizardStep';
import Loader from '../../Loader';

function LicenceAndTerms() {
  const params = useParams();
  const { data, loading: getLoading } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });
  const navigate = useNavigate();
  const [odpsJson, setOdpsJson] = useState<any | undefined>(undefined);
  const [upsertDataProduct, { loading: upsertLoading }] = useUpsertDataProductMutation({});
  const {
    register,
    handleSubmit,
  } = useForm<OdpsFields>();

  useEffect(() => {
    if (data?.dataMarket?.userProduct && params.productId) {
      const odps = JSON.parse(data?.dataMarket.userProduct.openDataProductSpecification);
      setOdpsJson(odps);
      syncOfferWithLocalStorage(params.productId, data?.dataMarket.userProduct.id);
    }
  }, [data]);

  function updateOdpsJson(rawOdps: string | undefined, fields: OdpsFields): string {
    const parsedOdps = JSON.parse(rawOdps ?? '{}');
    return JSON.stringify({
      ...parsedOdps,
      product: {
        ...parsedOdps?.product ?? [],
        en: {
          ...parsedOdps?.product?.en ?? [],
          productID: fields.productId,
        },
        license: {
          ...parsedOdps?.product?.license ?? [],
          scope: {
            ...parsedOdps?.product?.license?.scope ?? [],
            restrictions: fields.restrictions,
            rights: [fields.license],
          },
        },
      },
    });
  }

  const onSubmit = handleSubmit((fields) => {
    upsertDataProduct({
      variables: {
        command: {
          id: data?.dataMarket.userProduct.id ?? params.productId,
          dataType: data?.dataMarket.userProduct.dataType ?? DataProductDataType.Common,
          openDataProductSpecification: updateOdpsJson(data?.dataMarket.userProduct.openDataProductSpecification, fields),
          version: 1, // TODO: Fix backend endpoint, we can only write version but not read...
        },
      },
      context: {
        headers: {
          [WeCityOrganizationIdHeader]: params.orgId,
        },
      },
    }).then((_) => {
      navigate(OfferWizardDataHolderRoute(params.orgId, params.productId));
    });
  });

  return (
    <WizardStep form={DataProductForm} currentStep={5} onSubmit={onSubmit}>
      {!getLoading && odpsJson ? (
        <>
          <FormField fieldName="product-id" label="Administratieve naam of Product ID" appendAsterix instruction="Max. 25 karakters">
            <TextInput
              id="title"
              placeholder="bijv. 2100_Lichtmasten"
              {...register('productId', { value: odpsJson?.product?.en?.productID ?? '', required: true, maxLength: 25 })}
            />
          </FormField>
          <FormField fieldName="usage" label="Hoe mag uw dataproduct na aanschaf gebruikt worden?" appendAsterix hint="Er gelden specifieke afspraken tussen jou en de gebruikerspartij">
            <Select id="license" color="info" className="max-w-screen-md" {...register('license', { value: (odpsJson?.product?.license?.scope?.rights[0] ?? '0001') })}>
              <option value="0001">
                0001: Licentiehouder deelt data alleen met Overeenkomstige Partijen (gebruik alleen voor DMI leden)
              </option>
              <option value="0002">
                0002: Licentiehouder gebruikt de data alleen voor intern gebruik
              </option>
              <option value="0003">
                0003: Licentiehouder gebruikt de data alleen voor niet-commercieel gebruik en zal de gegevens niet gebruiken om inkomsten te genereren
              </option>
              <option value="0004">
                0004: Licentiehouder verrijkt de ontvangen data met eigen gegevens voordat deze opnieuw worden gedeeld
              </option>
              <option value="0005">
                0005: Licentiehouder verrijkt de ontvangen data met andere gegevens voordat deze opnieuw worden gedeeld
              </option>
              <option value="0006">
                0006: Licentiehouder verrijkt ontvangen data met eigen data voordat deze opnieuw worden gedeeld op een niet-commerciële basis
              </option>
              <option value="0007">
                0007: Licentiehouder verrijkt ontvangen data met andere data voordat deze opnieuw worden gedeeld op een niet-commerciële basis
              </option>
              <option value="9999">
                9999: Licentiehouder gebruikt de data zoals tussen partijen is bepaald (additionele overeenkomst vereist)
              </option>
            </Select>
          </FormField>
          <FormField fieldName="restrictions" label="Hoe mag de data van uw dataproduct gebruikt worden?" appendAsterix instruction="Max. 500 karakters">
            <Textarea
              rows={8}
              id="restrictions"
              placeholder="Wat zijn de restricties voor afnemende partijen?"
              required
              maxLength={500}
              {...register('restrictions', { value: odpsJson?.product?.license?.scope?.restrictions ?? '' })}
            />
          </FormField>
          <div className="flex gap-x-4 mx-auto">
            <Link to={OfferWizardPricingPlansRoute(params.orgId, params.productId)}>
              <FlowBiteButton size="lg" color="light">
                <div className="w-full justify-center items-center gap-2 inline-flex">
                  <div className="text-xs lg:text-sm font-medium w-full">Back</div>
                </div>
              </FlowBiteButton>
            </Link>

            {!upsertLoading ? (
              <Button
                type="submit"
                className="flex items-center justify-self-center self-start rounded-lg text-center font-medium"
              >
                <div className="px-0.5 py-0.5">Next</div>
              </Button>
            ) : (
              <Button
                disabled
                type="button"
                className="inline-flex opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
              >
                <div className="px-0.5 py-0.5">Next</div>
              </Button>
            )}
          </div>
        </>
      ) : <Loader />}
    </WizardStep>
  );
}

type OdpsFields = {
  productId: string;
  license: string;
  restrictions: string;
};

export default LicenceAndTerms;
