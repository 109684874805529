import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Table } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import Badge from './Badge';
import Card from './Card';
import CategoriesToIcons from './CategoriesToIcons';
import InfoButton from './InfoButton';
import TagList from './TagList';
import { ODPS, randomColorWithSeed } from '../../Helpers';
import { ReactComponent as GoldIcon } from '../../images/library/pricing/gold.svg';
import { ReactComponent as SilverIcon } from '../../images/library/pricing/silver.svg';
import { ReactComponent as BronzeIcon } from '../../images/library/pricing/bronze.svg';
import { DataProductPlanSubscription } from '../../generated/gql/types';

function ProductCard({
  odps, productRoute, subscription, isMini,
}: ProductProps) {
  const pricingPlan = odps.product.pricingPlans.en.find((plan) => plan['x-wecity'].planId === subscription?.planId);
  let tier = 'onbekend';
  if (pricingPlan && 'x-wecity' in pricingPlan) {
    tier = pricingPlan['x-wecity']?.tier ?? 'bronze';
  }

  function renderTierIcon() {
    if (pricingPlan && 'x-wecity' in pricingPlan) {
      if (tier === 'gold') {
        return (<GoldIcon />);
      } if (tier === 'silver') {
        return <SilverIcon />;
      }
    }

    return <BronzeIcon />;
  }

  function renderHeader() {
    return (
      <div
        style={{ backgroundColor: randomColorWithSeed(odps.product.en.name.length) }}
        className="hidden pt-0 lg:inline-flex w-full h-36 justify-center items-center gap-6 rounded-t-lg lg:text-2xl"
      >
        {subscription ? (
          <div
            className="w-full h-full py-5 relative justify-start items-start inline-flex overflow-hidden rounded-t-lg"
          >
            {subscription.active ? (
              <div
                className="absolute w-64 h-32 origin-top-left rotate-45 bg-green-400 justify-center items-start gap-2.5 inline-flex"
              />
            ) : (
              <div
                className="absolute w-64 h-32 origin-top-left rotate-45 bg-orange-400 justify-center items-start gap-2.5 inline-flex"
              />
            )}
            <div className="absolute pl-3 pb-3 left-0 bottom-0">
              <div className="w-9">
                {renderTierIcon()}
              </div>
            </div>

            {(odps?.product?.en?.categories ?? []).length > 0 && (
            <div className="grow shrink basis-0 self-stretch justify-center items-center gap-6 flex">
              <CategoriesToIcons categories={odps?.product?.en?.categories ?? []} />
            </div>
            )}
          </div>
        ) : (
          <CategoriesToIcons categories={odps?.product?.en?.categories ?? []} />
        )}
      </div>
    );
  }

  function renderDetailsButton() {
    if (!productRoute) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    if (subscription) {
      return (
        <div className="pt-2 gap-x-4 justify-center lg:justify-end items-center inline-flex w-full">
          <Link to={productRoute}>
            <InfoButton>
              <div className="text-xs lg:text-sm font-medium whitespace-nowrap">Meer informatie</div>
              <FontAwesomeIcon
                icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
                size="lg"
                className="text-white"
              />
            </InfoButton>
          </Link>
        </div>
      );
    }

    return (
      <div className="flex pt-2 justify-center lg:justify-end w-full">
        <Link to={productRoute}>
          <InfoButton>
            <div className="text-xs lg:text-sm font-medium">Meer informatie</div>
            <FontAwesomeIcon
              icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
              size="lg"
              className="text-white"
            />
          </InfoButton>
        </Link>
      </div>
    );
  }

  return (
    <Card
      header={renderHeader()}
      backgroundColor="white"
      isMini={isMini}
    >
      <div className="flex">
        {subscription ? (
          <div className="flex-col bg-blue-400 rounded-tl-lg rounded-bl-lg lg:hidden">
            <div className="flex w-[68px] h-full pb-5 overflow-hidden items-end relative rounded-tl-lg">
              {subscription.active ? (
                <div
                  className="absolute w-56 h-24 top-4 -left-4 rotate-45 bg-green-400 self-start gap-2.5"
                />
              ) : (
                <div
                  className="absolute w-56 h-24 top-4 -left-4 rotate-45 bg-orange-400 self-start gap-2.5"
                />
              )}
              <div className="absolute pt-1 pl-3 justify-center self-start">
                {renderTierIcon()}
              </div>
              <div className="flex-col flex mx-auto max-w-[24px] space-y-4 text-xs lg:text-base">
                {(odps?.product?.en?.categories ?? []).length > 0 && (
                <CategoriesToIcons categories={odps?.product?.en?.categories ?? []} />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-col p-5 bg-blue-400 rounded-tl-lg rounded-bl-lg lg:hidden">
            <div className="flex h-full items-center">
              {(odps?.product?.en?.categories ?? []).length > 0 && (
              <div className="flex-col space-y-4 text-xs lg:text-base">
                <CategoriesToIcons categories={odps?.product?.en?.categories ?? []} />
              </div>
              )}
            </div>
          </div>
        )}
        <div className="flex-col grow p-3 lg:p-5 justify-between items-center inline-flex">
          {(odps?.product?.en?.categories ?? []).length > 0 && (
            <div className="w-full h-5 justify-start items-start gap-2 inline-flex mb-2">
              {(odps?.product?.en?.categories ?? []).map((category: string) => (
                <Badge key={category}>{category}</Badge>
              ))}
            </div>
          )}

          <div className="flex-col justify-start items-start inline-flex w-full">
            <div className="text-left w-full text-gray-900 text-base lg:text-2xl font-bold">{odps?.product?.en?.name}</div>
            {!isMini && (
              <div
                className="basis-0 text-gray-500 text-base font-normal leading-normal"
              >
                <p className="line-clamp-3 mb-2 text-sm lg:text-base">{odps?.product?.en?.description}</p>
              </div>
            )}
          </div>
          <div className="pb-1">
            <TagList tags={odps?.product?.en?.tags} limit={5} />
          </div>
          <div className="w-full">
            <Table className="w-full">
              <Table.Body className="overflow-x-hidden text-left w-full items-start">
                <Table.Row className="border-t border-solid border-gray-200 ">
                  <Table.Cell className="text-gray-400 p-0 pr-1 py-1 inline-flex space-x-2">
                    <div className="text-gray-400">
                      Aanbieder
                    </div>
                    <div className="text-gray-800 font-medium whitespace-nowrap">
                      {odps?.product?.dataHolder?.legalName ?? 'unknown'}
                    </div>
                  </Table.Cell>
                  <Table.Cell className="p-1 px-2 inline-flex  space-x-2">
                    <div className="text-gray-400">
                      Formaat
                    </div>
                    <div className="text-gray-800 font-medium whitespace-nowrap">
                      {odps?.product?.dataAccess?.format ?? 'unknown'}
                    </div>
                  </Table.Cell>
                </Table.Row>
                {subscription && (
                <Table.Row className="border-t border-solid border-gray-200 ">
                  <Table.Cell className="p-0 pr-1 py-1 inline-flex justify-between space-x-2 w-full text-sm">
                    <div className="text-gray-800 font-medium gap-x-2 items-center">
                      {subscription.active ? (
                        <>
                          <FontAwesomeIcon
                            icon={icon({ name: 'circle', family: 'classic', style: 'solid' })}
                            size="1x"
                            className="text-green-400 pr-1"
                          />
                          {' '}
                          Actief
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={icon({ name: 'circle', family: 'classic', style: 'solid' })}
                            size="1x"
                            className="text-orange-400 pr-1"
                          />
                          In behandeling
                        </>
                      )}
                    </div>
                    <div className="text-gray-800 flex font-medium gap-x-2 whitespace-nowrap capitalize items-center">
                      {tier}
                      <div className="text-gray-400 text-xs">
                        sinds
                        {' '}
                        {format(parseISO(subscription.createdAt), 'dd/MM/y', { locale: nl })}
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
          {(!isMini || !productRoute) && (
            renderDetailsButton()
          )}
        </div>
      </div>
    </Card>
  );
}

export default ProductCard;

type ProductProps = {
  odps: ODPS
  productRoute?: string
  subscription?: Partial<DataProductPlanSubscription>
  isMini?: boolean
};
