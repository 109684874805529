import React from 'react';
import { useLocation } from 'react-router-dom';
import { type DeepPartial, Navbar } from 'flowbite-react';
import { FlowbiteNavbarLinkTheme } from 'flowbite-react/lib/esm/components/Navbar/NavbarLink';
import { MySubscriptionsOverviewRoute } from '../Routes';
import useDefaultDataMarketOrganization from '../hooks/useDefaultDataMarketOrganization';

function MySubscriptionsNavBarLink() {
  const location = useLocation();
  const defaultOrgId = useDefaultDataMarketOrganization();

  const customLinkTheme: DeepPartial<FlowbiteNavbarLinkTheme> = {
    base: 'pl-0 text-base h-[64px] items-center',
    active: {
      on: 'text-white border-primary-600 border-b-2 box-sizing bg-gray-50',
      off: 'border-b-2 border-white',
    },
  };

  if (!defaultOrgId) {
    return <div />;
  }

  if (location.pathname === MySubscriptionsOverviewRoute()) {
    return (
      <Navbar.Link
        href={MySubscriptionsOverviewRoute(defaultOrgId)}
        active
        theme={customLinkTheme}
        className="px-4 text-base flex text-gray-700 font-bold"
      >
        Mijn Producten (
        {defaultOrgId}
        )
      </Navbar.Link>
    );
  }

  return (
    <Navbar.Link
      href={MySubscriptionsOverviewRoute(defaultOrgId)}
      theme={customLinkTheme}
      className="px-4 text-base flex text-gray-700 font-bold"
    >
      Mijn Producten (
      {defaultOrgId}
      )
    </Navbar.Link>
  );
}

export default MySubscriptionsNavBarLink;
