import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button, Button as FlowBiteButton, TextInput,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { syncOfferWithLocalStorage } from '../../../Helpers';
import { DataProductDataType, useGetMyProductQuery, useUpsertDataProductMutation } from '../../../generated/gql/types';
import {
  OfferWizardImagesRoute, OfferWizardLicenseAndTermsRoute,
} from '../../Routes';
import FormField from '../../library/form/FormField';
import DataProductForm from '../../../productForms';
import WizardStep from '../../library/form/WizardStep';
import Loader from '../../Loader';

function DataHolder() {
  const params = useParams();
  const { data, loading: getLoading } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });

  const navigate = useNavigate();
  const [upsertDataProduct, { loading: upsertLoading }] = useUpsertDataProductMutation({});
  const [odpsJson, setOdpsJson] = useState<any | undefined>(undefined);
  const {
    register,
    handleSubmit,
  } = useForm<OdpsFields>();
  useEffect(() => {
    if (data?.dataMarket?.userProduct && params.productId) {
      const odps = JSON.parse(data?.dataMarket.userProduct.openDataProductSpecification);
      setOdpsJson(odps);
      syncOfferWithLocalStorage(params.productId, data?.dataMarket.userProduct.id);
    }
  }, [data]);

  function updateOdpsJson(rawOdps: string | undefined, fields: OdpsFields): string {
    const parsedOdps = JSON.parse(rawOdps ?? '{}');

    return JSON.stringify({
      ...parsedOdps,
      product: {
        ...parsedOdps?.product ?? [],
        dataHolder: {
          ...parsedOdps?.product?.dataHolder ?? [],
          legalName: fields.legalName,
          businessId: fields.coc,
          email: fields.contact,
          URL: fields.url,
        },
      },
    });
  }

  const onSubmit = handleSubmit((fields) => {
    upsertDataProduct({
      variables: {
        command: {
          id: data?.dataMarket.userProduct.id ?? params.productId,
          dataType: data?.dataMarket.userProduct.dataType ?? DataProductDataType.Common,
          openDataProductSpecification: updateOdpsJson(data?.dataMarket.userProduct.openDataProductSpecification, fields),
          version: 1, // TODO: Fix backend endpoint, we can only write version but not read...
        },
      },
      context: {
        headers: {
          [WeCityOrganizationIdHeader]: params.orgId,
        },
      },
    }).then((_) => {
      navigate(OfferWizardImagesRoute(params.orgId, params.productId));
    });
  });

  return (
    <WizardStep form={DataProductForm} currentStep={6} onSubmit={onSubmit}>
      {!getLoading && odpsJson
        ? (
          <>
            <FormField fieldName="coc-name" label="Wettelijke naam van data-aanbieder" appendAsterix>
              <TextInput
                className="max-w-screen-sm"
                id="legalName"
                placeholder="De naam zoals gerigistreerd bij de KVK"
                required
                {...register('legalName', { value: odpsJson?.product?.dataHolder?.legalName ?? '' })}
              />
            </FormField>

            <FormField label="Organisatie URL" fieldName="organisation-url" hint="De website van uw organisatie">
              <TextInput
                className="max-w-screen-sm"
                id="organisation-url"
                placeholder="http://example.com"
                required
                {...register('url', { value: odpsJson?.product?.dataHolder?.URL ?? '' })}
              />
            </FormField>

            <FormField fieldName="coc-id" label="KVK-nummer van de bezittende organisatie" appendAsterix hint="8-cijferig KVK nummer">
              <TextInput
                className="max-w-screen-sm"
                id="coc"
                placeholder="01234567"
                required
                minLength={8}
                maxLength={8}
                pattern="\d{8}"
                {...register('coc', { value: odpsJson?.product?.dataHolder?.businessId ?? '' })}
              />
            </FormField>

            <FormField fieldName="organisation-email" label="E-mailadres van data-aanbieder" appendAsterix>
              <TextInput
                className="max-w-screen-sm"
                id="email"
                placeholder="voorbeeld@wecity.nl"
                required
                type="email"
                {...register('contact', { value: odpsJson?.product?.dataHolder?.email ?? '' })}
              />
            </FormField>
            <div className="flex gap-x-4 mx-auto">
              <Link to={OfferWizardLicenseAndTermsRoute(params.orgId, params.productId)}>
                <FlowBiteButton size="lg" color="light">
                  <div className="w-full justify-center items-center gap-2 inline-flex">
                    <div className="text-xs lg:text-sm font-medium w-full">Back</div>
                  </div>
                </FlowBiteButton>
              </Link>
              {!upsertLoading ? (
                <Button
                  type="submit"
                  className="flex items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Next</div>
                </Button>
              ) : (
                <Button
                  disabled
                  type="button"
                  className="inline-flex opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Next</div>
                </Button>
              )}
            </div>
          </>
        ) : <Loader />}
    </WizardStep>
  );
}

type OdpsFields = {
  legalName: string;
  url: string
  coc: string;
  contact: string;
};

export default DataHolder;
