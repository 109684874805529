import React, { useState } from 'react';
import { Button as FlowBiteButton } from 'flowbite-react/lib/esm/components/Button/Button';
import {
  Label, Modal, TextInput,
} from 'flowbite-react';
import { ModalHeader } from 'flowbite-react/lib/esm/components/Modal/ModalHeader';
import { ModalBody } from 'flowbite-react/lib/esm/components/Modal/ModalBody';
import { ModalFooter } from 'flowbite-react/lib/esm/components/Modal/ModalFooter';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { CreateUserCommand, useCreateUserMutation } from '../../generated/gql/types';

function Registration() {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
  } = useForm<CreateUserCommand>();

  const [createUser, {
    loading: registerUserLoading,
    error: registerUserError,
  }] = useCreateUserMutation({});

  const onSubmit = handleSubmit((createUserCommand) => {
    createUser({
      variables: {
        command: createUserCommand,
      },
    }).then(() => {
      setModalOpen(false);
    }).catch((e) => {
      const scope = new Sentry.Scope();
      scope.setLevel('error');
      Sentry.captureMessage(e.toString(), scope);
    });
  });

  return (
    <>
      <FlowBiteButton size="sm" color="gray" onClick={() => { setModalOpen(true); }}>
        <div className="w-full justify-center items-center gap-2 inline-flex">
          Registreer als DMI deelnemer
        </div>
      </FlowBiteButton>

      <Modal show={modalOpen} popup onClose={() => setModalOpen(false)} dismissible>
        <ModalHeader>
          <div className="p-2">Registreer als DMI deelnemer</div>
        </ModalHeader>
        <ModalBody>
          <form id="registrationForm" className="max-w-md m-auto gap-2 flex flex-col" onSubmit={onSubmit}>
            <div className="flex justify-between mb-3">
              <div>
                <div className="mb-2">
                  <Label htmlFor="firstname" value="Voornaam:" />
                </div>
                <TextInput autoFocus id="firstname" placeholder="Uw voornaam" {...register('firstname', { required: true })} />
              </div>
              <div>
                <div className="mb-2">
                  <Label htmlFor="lastname" value="Achternaam:" />
                </div>
                <TextInput id="lastname" required placeholder="Uw achternaam" {...register('lastname', { required: true })} />
              </div>
            </div>
            <div className="block">
              <Label htmlFor="kvk" value="KVK-nummer:" />
            </div>
            <TextInput id="coc" required placeholder="KVK-nummer bestaande uit 8 getallen, bijv. 01234567" {...register('coc', { required: true, pattern: /\d{8}/ })} />
            <div className="block">
              <Label htmlFor="email" value="E-mailadres:" />
            </div>
            <TextInput id="email" type="email" required placeholder="Uw e-mailadres" {...register('email', { required: true })} />
          </form>
        </ModalBody>
        <ModalFooter className="flex-col gap-5">
          <FlowBiteButton size="sm" color="info" className="mx-auto" disabled={registerUserLoading} form="registrationForm" type="submit">
            <div className="w-full justify-center items-center gap-2 inline-flex">
              Registratie versturen
            </div>
          </FlowBiteButton>
          {registerUserError && (
          <div className="block text-sm">
            {`Error: ${registerUserError.message}`}
          </div>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Registration;
