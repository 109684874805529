import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button as FlowBiteButton } from 'flowbite-react/lib/esm/components/Button/Button';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { useGetMyProductQuery } from '../../../generated/gql/types';
import {
  MyOfferingsOverviewRoute, OfferWizardLanguageRoute,
} from '../../Routes';
import InfoButton from '../../library/InfoButton';
import { syncOfferWithLocalStorage } from '../../../Helpers';

function GetStarted() {
  const { idTokenPayload } = useOidcIdToken();
  const params = useParams();
  const { data } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });

  useEffect(() => {
    if (data?.dataMarket?.userProduct && params.productId) {
      syncOfferWithLocalStorage(params.productId, data?.dataMarket.userProduct.id);
    }
  }, [data]);

  return (
    <div
      className="mx-auto relative max-w-screen-xl flex items-center justify-center w-full flex-col p-10 bg-white space-y-2 my-5"
    >
      <div className="self-start">
        <Link to={MyOfferingsOverviewRoute(params.orgId)}>
          <div className="flex gap-x-2 items-center">
            <FontAwesomeIcon
              icon={icon({ name: 'angle-left', family: 'classic', style: 'solid' })}
              size="lg"
              className="text-primary-500"
            />
            <div className="text-primary-500">Back to my offerings</div>
          </div>
        </Link>
      </div>
      <div className="text-gray-900 text-3xl font-bold">Get started offering a product</div>

      <div className="text-gray-900 text-base font-bold self-start">Is this information up to date?</div>
      <div className="bg-gray-200 w-full p-5">
        <div className="flex gap-x-2">
          <div className="font-bold">My Organization name:</div>
          <div>{params.orgId}</div>
        </div>
        <div className="flex gap-x-2">
          <div className="font-bold">My Email:</div>
          <div>{idTokenPayload.email}</div>
        </div>
      </div>

      <div className="flex gap-x-4">
        <Link to={MyOfferingsOverviewRoute(params.orgId)}>
          <FlowBiteButton size="lg" color="light">
            <div className="w-full justify-center items-center gap-2 inline-flex">
              <div className="text-xs lg:text-sm font-medium w-full">No it is not. Go to edit my profile.</div>
            </div>
          </FlowBiteButton>
        </Link>

        <Link to={OfferWizardLanguageRoute(params.orgId, params.productId)}>
          <InfoButton>
            <div className="text-xs lg:text-sm font-medium w-full">Yes, this is correct.</div>
            <FontAwesomeIcon
              icon={icon({ name: 'arrow-right', family: 'classic', style: 'solid' })}
              size="lg"
              className="text-white"
            />
          </InfoButton>
        </Link>
      </div>
    </div>
  );
}

export default GetStarted;
