import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Badge, Button, Button as FlowBiteButton, Select,
} from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { syncOfferWithLocalStorage } from '../../../Helpers';
import { DataProductDataType, useGetMyProductQuery, useUpsertDataProductMutation } from '../../../generated/gql/types';
import { OfferWizardYourDatasetRoute, OfferWizardYourProductRoute } from '../../Routes';
import FormField from '../../library/form/FormField';
import WizardStep from '../../library/form/WizardStep';
import DataProductForm from '../../../productForms';
import Loader from '../../Loader';

function ProductVisibility() {
  const navigate = useNavigate();
  const params = useParams();
  const { data, loading: getLoading } = useGetMyProductQuery({
    fetchPolicy: 'network-only',
    context: {
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
    variables: {
      id: params.productId ?? '',
    },
  });

  const [selectedThemes, setSelectedThemes] = useState<string[]>([]);
  const [selectedVisibility, setSelectedVisibility] = useState<string>('private');

  const [upsertDataProduct, { loading: upsertLoading }] = useUpsertDataProductMutation({});
  const {
    handleSubmit,
  } = useForm<OdpsFields>();

  useEffect(() => {
    if (data?.dataMarket?.userProduct && params.productId) {
      const odps = JSON.parse(data?.dataMarket.userProduct.openDataProductSpecification);
      setSelectedVisibility(odps?.product?.en?.visibility ?? 'private');
      setSelectedThemes(odps?.product?.en?.tags ?? []);
      syncOfferWithLocalStorage(params.productId, data?.dataMarket.userProduct.id);
    }
  }, [data]);

  const isThemeSelected = (theme: string) => selectedThemes.includes(theme);
  const toggleTheme = (theme: string) => {
    if (isThemeSelected(theme)) {
      setSelectedThemes(selectedThemes.filter((t) => t !== theme));
    } else if (selectedThemes.length < 4) {
      setSelectedThemes([...selectedThemes, theme]);
    }
  };

  function updateOdpsJson(rawOdps: string | undefined): string {
    const parsedOdps = JSON.parse(rawOdps ?? '{}');

    return JSON.stringify({
      ...parsedOdps,
      product: {
        ...parsedOdps?.product ?? [],
        en: {
          ...parsedOdps?.product?.en ?? [],
          tags: selectedThemes,
          visibility: selectedVisibility,
        },
      },
    });
  }

  const onSubmit = handleSubmit(() => {
    upsertDataProduct({
      variables: {
        command: {
          id: data?.dataMarket.userProduct.id ?? params.productId,
          dataType: data?.dataMarket.userProduct.dataType ?? DataProductDataType.Common,
          openDataProductSpecification: updateOdpsJson(data?.dataMarket.userProduct.openDataProductSpecification),
          version: 1, // TODO: Fix backend endpoint, we can only write version but not read...
        },
      },
      context: {
        headers: {
          [WeCityOrganizationIdHeader]: params.orgId,
        },
      },
    }).then((_) => {
      navigate(OfferWizardYourDatasetRoute(params.orgId, params.productId));
    });
  });

  const themes = ['Slimme verstedelijking', 'Hubs en woningbouw', 'Slimme logistiek', 'Openbaar vervoer', 'Stedelijk verkeer', 'Kennis', 'Duurzaamheid'];

  return (
    <WizardStep form={DataProductForm} currentStep={2} onSubmit={onSubmit}>
      {!getLoading
        ? (
          <>
            <FormField fieldName="themes" label="Thema's" appendAsterix instruction="Selecteer maximaal 4 thema's">
              <div className="flex flex-wrap gap-2 p-2 border-1 border rounded-xl max-w-screen-md">
                {
                  themes.map((theme) => (
                    <Badge key={theme} className="px-2 inline" color={isThemeSelected(theme) ? 'success' : 'info'}>
                      <button
                        type="button"
                        className="flex rounded-l-md text-sm font-medium text-gray-500 center-content"
                        onClick={() => {
                          toggleTheme(theme);
                        }}
                      >
                        {theme}
                        {isThemeSelected(theme) && <FontAwesomeIcon className="my-auto ml-2" icon={icon({ name: 'xmark', family: 'classic', style: 'regular' })} color="gray" />}
                      </button>
                    </Badge>
                  ))
                }
              </div>
            </FormField>

            <FormField fieldName="title" label="Zichtbaarheid van je data in de catalogus" appendAsterix>
              <Select id="visibility" color="info" className="max-w-screen-md" required value={selectedVisibility} onChange={(e) => setSelectedVisibility(e.target.value)}>
                <option value="private">
                  Private: Alleen jij als creator kan de data zien
                </option>
                <option value="organisation">
                  Organisatie: Alleen leden binnen jouw organisatie kunnen de data zien
                </option>
                <option value="public">
                  Openbaar: Iedereen kan de data zien
                </option>
              </Select>
            </FormField>

            <div className="flex gap-x-4">
              <Link to={OfferWizardYourProductRoute(params.orgId, params.productId)}>
                <FlowBiteButton size="lg" color="light">
                  <div className="w-full justify-center items-center gap-2 inline-flex">
                    <div className="text-xs lg:text-sm font-medium w-full">Back</div>
                  </div>
                </FlowBiteButton>
              </Link>

              {!upsertLoading ? (
                <Button
                  type="submit"
                  className="flex items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Next</div>
                </Button>
              ) : (
                <Button
                  disabled
                  type="button"
                  className="inline-flex opacity:50 items-center justify-self-center self-start rounded-lg text-center font-medium"
                >
                  <div className="px-0.5 py-0.5">Next</div>
                </Button>
              )}
            </div>
          </>
        ) : <Loader />}
    </WizardStep>
  );
}

type OdpsFields = {
  visibility: string,
  themes: string[]
};

export default ProductVisibility;
